export const LocalStorageKeys = {
  COORDINATE_FORMAT: "coordinate-format",
  DEVELOPER_OPTIONS: "athena-dev-options",
  INCIDENTS_DRAWER_SETTINGS: "athena-incidents-drawer-settings",
  INCIDENTS_DRAWER_SETTINGS_COP: "athena-incidents-drawer-settings-cop",
} as const;

export const SessionStorageKeys = {
  ACTIVE_LAYERS_COP: "active-layers-cop",
  ACTIVE_LAYERS_FIRE_MODELLING: "active-layers-fire-modelling",
  ACTIVE_LAYERS_STATE_VIEW: "active-layers-state-view",
} as const;
